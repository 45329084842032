import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { Session } from 'src/app/models/session';

@Component({
  selector: 'uxd-credentials',
  templateUrl: './credentials.component.html',
  styleUrls: ['./credentials.component.scss']
})
export class CredentialsComponent implements OnInit {
  session: Session;

  constructor(public sessionService: SessionService) {}

  ngOnInit() {
    this.session = this.sessionService.getSession();
  }
}
