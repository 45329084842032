<p>GUID: {{ uiElement.guid }}</p>

<div
  id="holder"
  [style.min-width]="'200px'"
  [style.min-height]="'200px'"
  (drop)="onDrop($event, 'image')"
  (dragover)="onDragOver($event)"
  (paste)="processPasted($event)"
>
  <div tabindex="0" class="dragtarget" [ngClass]="uiElement.imagePath ? '' : 'empty'">
    <div
      style="
      position: relative; margin: auto; display: inline-block;"
    >
      <div style="pointer-events: none">
        <img
          [style.width.px]="
            uiElement.imageDimensions.width * (uiElement.imageDimensions.scale * viewScale)
          "
          [style.height.px]="
            uiElement.imageDimensions.height * (uiElement.imageDimensions.scale * viewScale)
          "
          *ngIf="elementImage"
          [src]="elementImage | safe: 'url'"
          [alt]="uiElement.imagePath"
          onerror="this.src='assets/img/warning.png'"
          (error)="notLoggedIn()"
        />
      </div>
      <div *ngIf="!uiElement.imagePath || uiElement.imagePath === ''">drag image here</div>
      <div *ngIf="uiElement.imagePath">
        <uxd-map-area
          *ngFor="let relatedItem of uiElement.related"
          [currentUiElement]="relatedItem"
          [parentUiElement]="uiElement.guid"
        >
        </uxd-map-area>
      </div>
    </div>
  </div>
</div>

<!-- <img class="element-image" [src]="uiElement.imagePath" /> -->
<mat-slide-toggle style="margin: 10px;" [(ngModel)]="uiElement.hideImage">
  Hide image (use only for thumbnail)
</mat-slide-toggle>
<br />
<mat-form-field class="image-width-form-field">
  <input
    matInput
    type="text"
    placeholder="Real image width"
    [(ngModel)]="uiElement.imageDimensions.width"
  />
</mat-form-field>
<br />
<mat-form-field class="image-height-form-field">
  <input
    matInput
    type="text"
    placeholder="Real image height"
    [(ngModel)]="uiElement.imageDimensions.height"
  />
</mat-form-field>
<br />
<mat-form-field class="image-scale-form-field">
  <input
    matInput
    type="text"
    placeholder="Image scale"
    [(ngModel)]="uiElement.imageDimensions.scale"
  />
</mat-form-field>
<br />
<mat-form-field class="img-url-form-field full-width">
  <input
    matInput
    type="text"
    placeholder="Image path (use external image instead of pasted image)"
    [(ngModel)]="uiElement.manualImagePath"
    (blur)="getImage()"
    (keydown)="getImageOnReturn($event)"
  />
</mat-form-field>
<br />

<mat-form-field class="name-form-field">
  <input matInput type="text" placeholder="List index" [(ngModel)]="uiElement.listIndex" />
</mat-form-field>
<br />

<!-- <mat-form-field class="property-form-field">
  <input
    matInput
    type="text"
    placeholder="Variant of (parent element guid)"
    [(ngModel)]="uiElement.variantOf"
  />
</mat-form-field> -->
<mat-form-field class="full-width">
  <mat-chip-list #tagList>
    <mat-chip
      *ngFor="let tag of uiElement.tags"
      selectable="true"
      removable="true"
      (removed)="remove(tag)"
    >
      {{ tag }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      placeholder="New tag..."
      #tagInput
      [formControl]="tagCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="tagList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      matChipInputAddOnBlur="true"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
      {{ tag }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field class="full-width">
  <mat-select
    [(ngModel)]="uiElement.variantOf"
    placeholder="Variant of (append this entity to)"
    [formControl]="variantOf"
  >
    <mat-option [value]="undefined">none</mat-option>
    <mat-option *ngFor="let listElement of uiElements" [value]="listElement.guid">{{
      listElement.name
    }}</mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field class="full-width">
  <mat-select
    [(ngModel)]="uiElement.related"
    placeholder="Related elements"
    [formControl]="relatedElements"
    multiple
  >
    <mat-option
      *ngFor="let listElement of uiElements | isType: uiElement.layout"
      [value]="listElement.guid"
      >{{ listElement.name }}</mat-option
    >
  </mat-select>
</mat-form-field>

<mat-expansion-panel [expanded]="true">
  <mat-expansion-panel-header>
    <mat-panel-title> Categories</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-list *ngIf="uiElement.categories[0]">
    <mat-list-item
      *ngFor="let category of uiElement.categories[0]; let i = index; trackBy: customTrackBy"
    >
      <mat-form-field class="property-form-field">
        <input
          matInput
          type="text"
          [(ngModel)]="uiElement.categories[0][i]"
          (blur)="rebuildNavigation()"
        />
        <button
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="removeItem(uiElement.categories[0], i)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </mat-list-item>
  </mat-list>
  <button mat-icon-button (click)="addItem(uiElement.categories[0])">
    <mat-icon aria-label="Plus">control_point</mat-icon>
  </button>
</mat-expansion-panel>
<br />

<mat-form-field class="full-width">
  <mat-select [(ngModel)]="uiElement.layout" placeholder="Type">
    <mat-option value="specification">specification</mat-option>
    <mat-option value="specific">specific</mat-option>
    <mat-option value="service">service</mat-option>
    <mat-option value="screen">screen</mat-option>
    <mat-option value="component">component</mat-option>
    <mat-option value="control">control</mat-option>
    <mat-option value="element">element</mat-option>
    <mat-option value="icon">icon</mat-option>
    <mat-option value="color">color</mat-option>
    <mat-option value="font">font</mat-option>
    <mat-option value="image">image</mat-option>
    <mat-option value="measurement">measurement</mat-option>
  </mat-select>
</mat-form-field>

<!-- <mat-expansion-panel-header>
    <mat-panel-title> {{ propertyBlock.name }}</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-list>
    <mat-list-item
      *ngFor="
        let property of propertyBlock.list;
        let i = index;
        trackBy: customTrackBy
      "
    >
      <mat-form-field class="property-form-field">
        <input matInput type="text" [(ngModel)]="propertyBlock.list[i]" />
        <button
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="removeItem(propertyBlock.list, i)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </mat-list-item>
  </mat-list>
  <button mat-icon-button (click)="addItem(propertyBlock.list)">
    <mat-icon aria-label="Plus">control_point</mat-icon>
  </button>
</mat-expansion-panel> -->
