import { UiElement } from '../models/ui-element';

export let UIELEMENT: UiElement = {
  guid: 'default',
  layout: 'control',
  name: '',
  imagePath: '',
  manualImagePath: '',
  properties: [
    {
      name: 'Purpose',
      key: 'purpose',
      list: []
    },
    {
      name: 'Contents',
      key: 'contents',
      list: []
    },
    {
      name: 'Variants',
      key: 'variants',
      list: []
    },
    { name: 'Interaction', key: 'interaction', list: [] },
    { name: 'General Appearance', key: 'appearance', list: [] },
    { name: 'Measurements', key: 'measurements', list: [] }
  ],
  states: [],
  tags: [],
  related: [],
  categories: [['0 | MenuItem', '0 | SubMenuItem']],
  content: '',
  imageDimensions: { width: 100, height: 100, scale: 1 }
};
