import { Component, OnInit } from '@angular/core';
import { GitService } from 'src/app/services/git.service';
import { UiElement } from 'src/app/models/ui-element';
import { UiElementService } from 'src/app/services/ui-element.service';
import { SessionService } from 'src/app/services/session.service';
import { Session } from '../../models/session';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'uxd-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.scss']
})
export class MainViewComponent implements OnInit {
  constructor(
    private sessionService: SessionService,
    private gitService: GitService,
    private uiElementService: UiElementService,
    private navigationService: NavigationService
  ) {}
  currentUiElement = 'default';
  uiElements: UiElement[];
  session: Session;
  ngOnInit() {
    this.session = this.sessionService.getSession();
    this.uiElements = this.uiElementService.getUiElements();

  }
  getGit() {
    this.gitService.getGit().then((uiElements: UiElement[]) => {
      // console.log(uiElements);
      Object.assign(this.uiElements, uiElements);
      this.navigationService.updateNavigation();
      this.currentUiElement = this.uiElements[0].guid;
      // this.uiElements[0].name = uiElements[0].name;
      this.sessionService.resetChangedElements();
    });
  }
}
