<!-- <mat-toolbar color="primary"><button mat-icon-button (click)="addElement()">
  <mat-icon aria-label="Plus">control_point</mat-icon> Add Element
</button>
</mat-toolbar> -->

<mat-expansion-panel
  class="mat-elevation-z3 panel"
  [expanded]="false"
  *ngFor="let navigationItem of navigation.children"
>
  <mat-expansion-panel-header>
    <mat-panel-title
      ><b>{{ navigationItem.name }}</b></mat-panel-title
    >
  </mat-expansion-panel-header>
  <mat-action-list dense>
    <ng-container *ngFor="let uiElement of navigationItem.elements">
      <button
        class="list-item"
        [ngClass]="uiElement.guid === currentUiElement ? 'selected' : ''"
        aria-pressed="true"
        mat-list-item
        (click)="setCurrent(uiElement.guid)"
      >
      <b>{{ uiElement.name }}</b>
      </button>
    </ng-container>
  </mat-action-list>

  <mat-action-list dense>
    <ng-container *ngFor="let uiElement of navigation.elements">
      <button
        class="list-item"
        [ngClass]="uiElement.guid === currentUiElement ? 'selected' : ''"
        aria-pressed="true"
        mat-list-item
        (click)="setCurrent(uiElement.guid)"
      >
        {{ uiElement.name }}
      </button>
    </ng-container>
  </mat-action-list>

  <mat-expansion-panel
    class="mat-elevation-z0 panel2"
    [expanded]="false"
    *ngFor="let childNavigationItem of navigationItem.children"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>{{ childNavigationItem.name }}</mat-panel-title>
    </mat-expansion-panel-header>
    <mat-action-list dense>
      <ng-container *ngFor="let uiElement of childNavigationItem.elements">
        <button
          class="list-item"
          [ngClass]="uiElement.guid === currentUiElement ? 'selected' : ''"
          aria-pressed="true"
          mat-list-item
          (click)="setCurrent(uiElement.guid)"
        >
          {{ uiElement.name }}
        </button>
      </ng-container>
    </mat-action-list>
    <!--   -->
    <mat-expansion-panel
    class="mat-elevation-z0 panel3"
    [expanded]="false"
    *ngFor="let childChildNavigationItem of childNavigationItem.children"
  >
    <mat-expansion-panel-header>
      <mat-panel-title><small>{{ childChildNavigationItem.name }}</small></mat-panel-title>
    </mat-expansion-panel-header>
    <mat-action-list dense>
      <ng-container *ngFor="let uiElement of childChildNavigationItem.elements">
        <button
          class="list-item"
          [ngClass]="uiElement.guid === currentUiElement ? 'selected' : ''"
          aria-pressed="true"
          mat-list-item
          (click)="setCurrent(uiElement.guid)"
        >
      <small>{{ uiElement.name }}</small>
        </button>
      </ng-container>
    </mat-action-list>
  </mat-expansion-panel>
    <!---->
  </mat-expansion-panel>
</mat-expansion-panel>

<!-- <mat-expansion-panel
  class="mat-elevation-z0 panel"
  [expanded]="false"
  *ngFor="let elementType of elementTypes"
>
  <mat-expansion-panel-header>
    <mat-panel-title>{{ elementType.name }}</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-action-list dense>
    <ng-container *ngFor="let uiElement of uiElements">
      <button
        class="list-item"
        [ngClass]="uiElement.guid === currentUiElement ? 'selected' : ''"
        aria-pressed="true"
        *ngIf="uiElement.layout === elementType.layout"
        mat-list-item
        (click)="setCurrent(uiElement.guid)"
      >
        {{ uiElement.name }}
      </button>
    </ng-container>
  </mat-action-list>
  <button mat-icon-button (click)="addElement(elementType)">
    <mat-icon aria-label="Plus">control_point</mat-icon>
  </button>
</mat-expansion-panel> -->
