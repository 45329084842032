import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UiElement } from 'src/app/models/ui-element';
import { UiElementService } from 'src/app/services/ui-element.service';
import { UIELEMENT } from '../../default-data/default-uielement';
import * as uuidv4 from 'uuid/v4';
import { NavigationService } from 'src/app/services/navigation.service';
import { NavigationElement } from 'src/app/models/navigation-element';

@Component({
  selector: 'uxd-element-list',
  templateUrl: './element-list.component.html',
  styleUrls: ['./element-list.component.scss']
})
export class ElementListComponent implements OnInit {
  @Input() currentUiElement = '';
  @Output() currentUiElementChange: EventEmitter<any>;
  uiElements: UiElement[];
  navigation: NavigationElement;
  elementTypes = [
    { layout: 'specification', name: 'Specification' },
    { layout: 'specific', name: 'Specific' },
    { layout: 'service', name: 'Services' },
    { layout: 'screen', name: 'Screens' },
    { layout: 'component', name: 'Components' },
    { layout: 'control', name: 'Controls' },
    { layout: 'element', name: 'Elements' },
    { layout: 'icon', name: 'Icons' },
    { layout: 'color', name: 'Colors' },
    { layout: 'font', name: 'Fonts' },
    { layout: 'image', name: 'Images' },
    { layout: 'measurement', name: 'Measurements' }
  ];

  constructor(
    private uiElementService: UiElementService,
    private navigationService: NavigationService
  ) {
    this.currentUiElementChange = new EventEmitter(true);
  }

  ngOnInit() {
    this.navigation = this.navigationService.getNavigation();
    this.uiElements = this.uiElementService.getUiElements();
  }
  setCurrent(guid: string) {
    this.currentUiElement = guid;
    this.currentUiElementChange.emit(this.currentUiElement);
  }
  /* addElement() {
    // const uiElement = new UiElement();
    const uiElement = Object.assign({}, UIELEMENT);
    console.log(UIELEMENT);
    uiElement.guid = uuidv4();
    uiElement.layout = 'control';
    uiElement.categories = JSON.parse(JSON.stringify(this.uiElementService.getUiElement(this.currentUiElement).categories));

    // uiElement.metaData = [{ name: 'Categories', key: 'categories', list: [] }];
    this.uiElements.push(uiElement);
    this.currentUiElement = uiElement.guid;
    this.currentUiElementChange.emit(this.currentUiElement);
    this.navigationService.updateNavigation();
    console.log(uiElement);
  } */
  /* addElement(elementType) {
    // const uiElement = new UiElement();
    const uiElement = Object.assign({}, UIELEMENT);
    console.log(UIELEMENT);
    uiElement.guid = uuidv4();
    uiElement.layout = elementType.layout;

    // uiElement.metaData = [{ name: 'Categories', key: 'categories', list: [] }];
    this.uiElements.push(uiElement);
    this.currentUiElement = uiElement.guid;
    this.currentUiElementChange.emit(this.currentUiElement);
    console.log(uiElement);
  } */
}
