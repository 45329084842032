import { Injectable } from '@angular/core';
import { UIELEMENTS } from '../default-data/default-uielements';
import { RESETUIELEMENTS } from '../default-data/reset-uielements';

@Injectable({
  providedIn: 'root'
})
export class UiElementService {
  constructor() {}

  getUiElements() {
    return UIELEMENTS;
  }

  getUiElement(guid: string) {
    return this.getUiElements().find(uielement => uielement.guid === guid);
  }

  resetUiElements() {
    const uielements = this.getUiElements();
    console.log('resetting');
    uielements.splice(0, uielements.length);
    console.log(uielements.length);
    Object.assign(uielements, RESETUIELEMENTS);
  }
}
