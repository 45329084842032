<mat-toolbar color="primary" class="uxd-navbar mat-elevation-z6">
<uxd-header [(currentUiElement)]="currentUiElement"></uxd-header>
</mat-toolbar>
<mat-sidenav-container class="main-content">
  <mat-sidenav opened mode="side" position="start"
    ><uxd-element-list
      [(currentUiElement)]="currentUiElement"
    ></uxd-element-list
  ></mat-sidenav>
  <mat-sidenav-content>
    <uxd-editor [currentUiElement]="currentUiElement"></uxd-editor>
  </mat-sidenav-content>
</mat-sidenav-container>
