import { Component, Input, OnChanges } from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop';
import { UiElement } from 'src/app/models/ui-element';
import { UiElementService } from 'src/app/services/ui-element.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'uxd-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss']
})
export class PropertiesComponent implements OnChanges {
  @Input() currentUiElement = '';
  uiElement: UiElement;
  uiElements: UiElement[];

  relatedElements = new FormControl();

  constructor(private uiElementService: UiElementService) {}

  ngOnChanges() {
    this.uiElement = this.uiElementService.getUiElement(this.currentUiElement);
    this.uiElements = this.uiElementService.getUiElements();
  }

  addItem(target: string[]) {
    target.push('');
    console.log(this.uiElement);
  }

  remove(tag: string): void {
    const index = this.uiElement.tags.indexOf(tag);

    if (index >= 0) {
      this.uiElement.tags.splice(index, 1);
    }
  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  removeItem(arrayObject, index) {
    arrayObject.splice(index, 1);
  }
}
