import { Session } from '../models/session';

export let SESSION: Session = {
  changedElements: [],
  gitCredentials: {
    apiUrl: 'https://gitlab.centigrade.de/',
    branch: 'master',
    path: 'source/_posts',
    imagePath: '',
    projectId: 0,
  },
};
