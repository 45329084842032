import { Injectable } from '@angular/core';
import { NAVIGATION } from '../default-data/default-navigation';
import { UiElementService } from './ui-element.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private uiElementService: UiElementService) {}

  getNavigation() {
    return NAVIGATION;
  }

  updateNavigation() {
    const navigation = this.getNavigation();
    const uiElements = this.uiElementService.getUiElements();
    navigation.children = [];
    navigation.elements = [];
    for (let n = 0; n < uiElements.length; n++) {
      if (
        uiElements[n].categories &&
        uiElements[n].categories.length > 0 &&
        uiElements[n].categories[0].length > 0
      ) {
        let categoryIndex = navigation.children.findIndex(
          child => child.name === uiElements[n].categories[0][0],
        );
        console.log(categoryIndex);
        if (categoryIndex < 0) {
          navigation.children.push({
            name: uiElements[n].categories[0][0],
            children: [],
            elements: [],
          });
          categoryIndex = navigation.children.length - 1;
        }

        if (uiElements[n].categories[0].length > 1) {
          let childCategoryIndex = navigation.children[categoryIndex].children.findIndex(
            child => child.name === uiElements[n].categories[0][1],
          );
          if (childCategoryIndex < 0) {
            navigation.children[categoryIndex].children.push({
              name: uiElements[n].categories[0][1],
              children: [],
              elements: [],
            });
            childCategoryIndex = navigation.children[categoryIndex].children.length - 1;
          }
          if (uiElements[n].categories[0].length > 2) {
            console.log(555);
            // -----
            // -----
            let childChildCategoryIndex = navigation.children[categoryIndex].children[
              childCategoryIndex
            ].children.findIndex(child => child.name === uiElements[n].categories[0][2]);
            if (childChildCategoryIndex < 0) {
              navigation.children[categoryIndex].children[childCategoryIndex].children.push({
                name: uiElements[n].categories[0][2],
                children: [],
                elements: [],
              });
              childChildCategoryIndex =
                navigation.children[categoryIndex].children[childCategoryIndex].children.length - 1;
            }
            if (uiElements[n].categories[0].length > 3) {
            } else {
              navigation.children[categoryIndex].children[childCategoryIndex].children[
                childChildCategoryIndex
              ].elements.push(uiElements[n]);
            }
            // -----
            // -----
          } else {
            navigation.children[categoryIndex].children[childCategoryIndex].elements.push(
              uiElements[n],
            );
          }
        } else {
          navigation.children[categoryIndex].elements.push(uiElements[n]);
        }
      } else {
        if (
          uiElements[n].categories &&
          uiElements[n].categories.length > 0 &&
          uiElements[n].categories[0].length < 2
        ) {
          navigation.elements.push(uiElements[n]);
        }
      }
    }
    navigation.children.sort((a, b) => a.name.localeCompare(b.name));
    for (let n = 0; n < navigation.children.length; n++) {
      navigation.children[n].children.sort((a, b) => a.name.localeCompare(b.name));
    }
    /* navigation.children = navigation.children.sort(function(a: any, b: any) {
      return a['name'] - b['name'];
    }); */
    console.log(navigation);
  }
}
