<mat-expansion-panel
  *ngFor="let propertyBlock of uiElement.properties"
  [expanded]="true"
>
  <mat-expansion-panel-header>
    <mat-panel-title> {{ propertyBlock.name }}</mat-panel-title>
  </mat-expansion-panel-header>
  <!-- {{ propertyBlock | json }} -->
  <mat-list
    cdkDropList
    [cdkDropListData]="propertyBlock.list"
    (cdkDropListDropped)="drop($event)"
  >
    <ng-container
      *ngFor="
        let property of propertyBlock.list;
        let i = index;
        trackBy: customTrackBy
      "
    >
      <mat-list-item style= "height: auto" cdkDrag *ngIf="!property.key">
        <mat-form-field class="property-form-field">
          <textarea
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="50"
            matInput
            [(ngModel)]="propertyBlock.list[i]"
          ></textarea>
          <button
            mat-button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="removeItem(propertyBlock.list, i)"
          >
            <mat-icon>close</mat-icon>
          </button>
          <div class="example-handle" cdkDragHandle>
            <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
              <path
                d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
              ></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          </div>
        </mat-form-field>
      </mat-list-item>

      <ng-container *ngIf="property.key">
        <mat-list-item
          ><b>{{ property.name }}</b></mat-list-item
        >
        <mat-list-item
          *ngFor="
            let subProperty of property.list;
            let k = index;
            trackBy: customTrackBy
          "
        >
          <mat-form-field class="property-form-field full-width">
            <input matInput type="text" [(ngModel)]="property.list[k]" />
            <button
              mat-button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="removeItem(property.list, k)"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </mat-list-item>
        <button mat-icon-button (click)="addItem(property.list)">
          <mat-icon aria-label="Plus">control_point</mat-icon>
        </button>
      </ng-container>
    </ng-container>
  </mat-list>
  <button mat-icon-button (click)="addItem(propertyBlock.list)">
    <mat-icon aria-label="Plus">control_point</mat-icon>
  </button>
</mat-expansion-panel>
