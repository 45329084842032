import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewChild,
  ChangeDetectionStrategy
} from '@angular/core';
import { UiElement } from 'src/app/models/ui-element';
import { UiElementService } from 'src/app/services/ui-element.service';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag
} from '@angular/cdk/drag-drop';
@Component({
  selector: 'uxd-map-area',
  templateUrl: './map-area.component.html',
  styleUrls: ['./map-area.component.scss'],
})
export class MapAreaComponent implements OnChanges {
  @Input() currentUiElement = '';
  @Input() parentUiElement = '';
  // x = 0;
  // y = 0;
  // @ViewChild('dragBox') dragBox: CdkDrag;
  uiElement: UiElement;
  containerUiElement: UiElement;
  viewScale = 2;
  dragging = false;

  constructor(private uiElementService: UiElementService) {}

  ngOnChanges() {
    this.uiElement = this.uiElementService.getUiElement(this.currentUiElement);
    this.containerUiElement = this.uiElementService.getUiElement(
      this.parentUiElement
    );
    // console.log(this.parentUiElement);
    // console.log(this.containerUiElement);
    if (!this.containerUiElement.imageMap) {
      this.containerUiElement.imageMap = [];
    }
    if (!this.containerUiElement.imageMap[this.currentUiElement]) {
      this.containerUiElement.imageMap[this.currentUiElement] = {
        x: 0,
        y: 0,
        width: 300,
        height: 200
      };
    }
    // this.x = this.containerUiElement.imageMap.x;
    // this.y = this.containerUiElement.imageMap.y;
    //  this.uiElements = this.uiElementService.getUiElements();
    // console.log(this.dragBox);
  }
  getCoordinates(event) {
    /* console.log(event.source._dragRef._activeTransform);
    console.log(event.event.layerX);
    console.log(event); */

    this.containerUiElement.imageMap[this.currentUiElement].x = Math.round(
      event.source._dragRef._activeTransform.x /
        (this.containerUiElement.imageDimensions.scale * this.viewScale)
    );
    this.containerUiElement.imageMap[this.currentUiElement].y = Math.round(
      event.source._dragRef._activeTransform.y /
        (this.containerUiElement.imageDimensions.scale * this.viewScale)
    );
  }
  setCoordinates(event) {
    // console.log(event);
    event.source._dragRef._activeTransform.x = event.source._dragRef._passiveTransform.x =
      this.containerUiElement.imageMap[this.currentUiElement].x *
      (this.containerUiElement.imageDimensions.scale * this.viewScale);
    event.source._dragRef._activeTransform.y = event.source._dragRef._passiveTransform.y =
      this.containerUiElement.imageMap[this.currentUiElement].y *
      (this.containerUiElement.imageDimensions.scale * this.viewScale);
    // this.x = event.source._activeTransform.x;
    // this.y = event.source._activeTransform.y;
  }
  resized(width, height) {
    console.log(width, height);
    this.containerUiElement.imageMap[this.currentUiElement].width = Math.round(
      width / (this.containerUiElement.imageDimensions.scale * this.viewScale)
    );
    this.containerUiElement.imageMap[this.currentUiElement].height = Math.round(
      height / (this.containerUiElement.imageDimensions.scale * this.viewScale)
    );
  }
}
