import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { UiElement } from 'src/app/models/ui-element';
import { UiElementService } from 'src/app/services/ui-element.service';

@Component({
  selector: 'uxd-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnChanges {
  @Input() currentUiElement = '';
  uiElement: UiElement;

  constructor(private uiElementService: UiElementService) {}

  ngOnChanges() {
    this.uiElement = this.uiElementService.getUiElement(this.currentUiElement);
  }
}
