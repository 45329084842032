<h1 mat-dialog-title>Repository Settings</h1>
<div mat-dialog-content class="mat-dialog-content">
  <mat-form-field>
    <mat-label>GitLab Repository</mat-label>
    <mat-select [(value)]="session.gitCredentials.apiUrl">
      <mat-option value="https://gitlab.centigrade.de/">External GitLab</mat-option>
      <mat-option value="http://gitlab.intranet.centigrade.de/">Internal GitLab</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="credentials-input">
    <mat-label>Path</mat-label>
    <input matInput [(ngModel)]="session.gitCredentials.path" />
  </mat-form-field>
  <mat-form-field class="credentials-input">
    <mat-label>Branch</mat-label>
    <input matInput [(ngModel)]="session.gitCredentials.branch" />
  </mat-form-field>
  <mat-form-field class="credentials-input">
    <mat-label>Project ID</mat-label>
    <input matInput [(ngModel)]="session.gitCredentials.projectId" />
  </mat-form-field>
  <mat-form-field class="credentials-input">
    <mat-label>Image Path</mat-label>
    <input matInput [(ngModel)]="session.gitCredentials.imagePath" />
  </mat-form-field>
</div>
<div mat-dialog-actions><button mat-flat-button color="accent" mat-dialog-close>Okay</button></div>
