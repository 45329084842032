<mat-tab-group [animationDuration]="'0ms'">
  <mat-tab
    *ngFor="let state of uiElement.states; let i = index"
    [label]="'State: ' + state.name || i"
  >
    <!-- <div
      id="holder"
      [style.min-width]="'200px'"
      [style.min-height]="'200px'"
      (drop)="onDrop($event, state.guid, i)"
      (dragover)="onDragOver($event)"
    >
      <div class="dragtarget" [ngClass]="state.src ? '' : 'empty'">
        <img
          *ngIf="state.src"
          style="max-width: 800px;"
          [src]="state.src | safe: 'url'"
        />
        <div *ngIf="!state.src">drag image here</div>
      </div>
    </div> -->

    <div
  id="holder"
  [style.min-width]="'200px'"
  [style.min-height]="'200px'"
  (drop)="onDrop($event, state.guid, i)"
  (dragover)="onDragOver($event)"
  (paste)="processPasted($event, i)"
>
  <div
    tabindex="0"
    class="dragtarget"
    [ngClass]="state.src ? '' : 'empty'"
  >
    <div
      style="
      position: relative; margin: auto; display: inline-block;"
    >
      <div style="pointer-events: none">
        <img
        [style.width.px]="uiElement.imageDimensions.width * (uiElement.imageDimensions.scale * viewScale)"
        [style.height.px]="uiElement.imageDimensions.height * (uiElement.imageDimensions.scale * viewScale)"
          *ngIf="state.src"
          [src]="state.src | safe: 'url'"
          [alt]="state.src"
          onerror="this.src='assets/img/warning.png'"
          (error)="notLoggedIn()"
        />
      </div>
      <div *ngIf="!state.src">drag image here</div>
    </div>
  </div>
</div>


<mat-form-field class="img-url-form-field full-width">
  <input
    matInput
    type="text"
    placeholder="Image path (use external image instead of pasted image)"
    [(ngModel)]="state.manualImagePath"
    (blur)="getStateImage(i)"
    (keydown)="getImageOnReturn($event, i)"
  />
</mat-form-field>
<br />

    <mat-form-field class="property-form-field">
      <input
        matInput
        type="text"
        [(ngModel)]="state.name"
        placeholder="State title"
      />
      <button
        mat-button
        *ngIf="state.name"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="state.name = ''"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <p>GUID: {{ state.guid }}</p>
    <!--  <mat-form-field class="full-width">
      <mat-label>State Description</mat-label>
      <textarea
        matInput
        [(ngModel)]="state.description"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="50"
      ></textarea>
    </mat-form-field> -->

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>State Description</mat-panel-title>
      </mat-expansion-panel-header>
      <!-- {{ propertyBlock | json }} -->
      <mat-list
        cdkDropList
        [cdkDropListData]="state.description"
        (cdkDropListDropped)="drop($event)"
      >
        <ng-container
          *ngFor="
            let property of state.description;
            let i = index;
            trackBy: customTrackBy
          "
        >
          <mat-list-item style="height: auto" cdkDrag>
            <mat-form-field class="property-form-field">
              <textarea
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="2"
                cdkAutosizeMaxRows="50"
                matInput
                [(ngModel)]="state.description[i]"
              >
              </textarea>
              <button
                mat-button
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="removeItem(state.description, i)"
              >
                <mat-icon>close</mat-icon>
              </button>
              <div class="example-handle" cdkDragHandle>
                <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                  <path
                    d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
                  ></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </div>
            </mat-form-field>
          </mat-list-item>
        </ng-container>
      </mat-list>
      <button mat-icon-button (click)="addItem(state.description)">
        <mat-icon aria-label="Plus">control_point</mat-icon>
      </button>
    </mat-expansion-panel>

    <mat-expansion-panel
      *ngFor="let propertyBlock of state.properties"
      [expanded]="true"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> {{ propertyBlock.name }}</mat-panel-title>
      </mat-expansion-panel-header>
      <!-- {{ propertyBlock | json }} -->
      <mat-list
        cdkDropList
        [cdkDropListData]="propertyBlock.list"
        (cdkDropListDropped)="drop($event)"
      >
        <ng-container
          *ngFor="
            let property of propertyBlock.list;
            let i = index;
            trackBy: customTrackBy
          "
        >
          <mat-list-item style="height: auto" cdkDrag *ngIf="!property.key">
            <mat-form-field class="property-form-field">
              <textarea
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="2"
                cdkAutosizeMaxRows="50"
                matInput
                type="text"
                [(ngModel)]="propertyBlock.list[i]"
              ></textarea>
              <button
                mat-button
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="removeItem(propertyBlock.list, i)"
              >
                <mat-icon>close</mat-icon>
              </button>
              <div class="example-handle" cdkDragHandle>
                <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                  <path
                    d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
                  ></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </div>
            </mat-form-field>
          </mat-list-item>

          <ng-container *ngIf="property.key">
            <mat-list-item
              ><b>{{ property.name }}</b></mat-list-item
            >
            <mat-list-item
              *ngFor="
                let subProperty of property.list;
                let k = index;
                trackBy: customTrackBy
              "
            >
              <mat-form-field class="property-form-field full-width">
                <input matInput type="text" [(ngModel)]="property.list[k]" />
                <button
                  mat-button
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="removeItem(property.list, k)"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </mat-list-item>
            <button mat-icon-button (click)="addItem(property.list)">
              <mat-icon aria-label="Plus">control_point</mat-icon>
            </button>
          </ng-container>
        </ng-container>
      </mat-list>
      <button mat-icon-button (click)="addItem(propertyBlock.list)">
        <mat-icon aria-label="Plus">control_point</mat-icon>
      </button>
    </mat-expansion-panel>
  </mat-tab>
</mat-tab-group>

<button mat-icon-button (click)="addState()">
  <mat-icon aria-label="Plus">control_point</mat-icon>
</button>
