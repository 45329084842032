
<button mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="openDialog()">
    <mat-icon>dialpad</mat-icon>
    <span>Edit credentials</span>
  </button>
  <button mat-menu-item (click)="getGit()">
    <mat-icon>refresh</mat-icon>
    <span>Refresh from GitLab</span>
  </button>
</mat-menu>
<button
  mat-raised-button
  color="accent"
  [matBadge]="this.session.changedElements.length"
  matBadgePosition="after"
  matBadgeColor="accent"
  (click)="commitChanges()"
>
  Commit changes
</button>
<button mat-icon-button (click)="addElement()" style = "margin-left: 60px;">
  <mat-icon aria-label="Plus">control_point</mat-icon> Add Element
</button>
<div class="flex-spacer"> Lean Scope Design System Guide Editor</div>
<uxd-theme-picker></uxd-theme-picker>

