import { Component } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { SessionService } from './services/session.service';

@Component({
  selector: 'uxd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public title = 'uxd';

  constructor(private oauthService: OAuthService, private sessionService: SessionService) {
    const gitCredentials = this.sessionService.getSession().gitCredentials;

    const iGitLabClientId = 'b41b523da774d23d4029351bed5bf776b8c9df68555c961d57dbb9d3cc5cecd3';
    const eGitLabClientId = '24fd8016192e3a049c956f88204caea7136ab5b08e8a35bd50c40ae639e36bcf';

    const clientId = gitCredentials.apiUrl.includes('intranet') ? iGitLabClientId : eGitLabClientId;

    // Login-Url
    this.oauthService.loginUrl = gitCredentials.apiUrl + 'oauth/authorize'; //Id-Provider?

    // URL of the SPA to redirect the user to after login
    this.oauthService.redirectUri = window.location.origin;

    // The SPA's id. Register SPA with this id at the auth-server
    this.oauthService.clientId = clientId;

    // set the scope for the permissions the client should request
    this.oauthService.scope = 'openid profile email api';

    // Use setStorage to use sessionStorage or another implementation of the TS-type Storage
    // instead of localStorage
    this.oauthService.setStorage(sessionStorage);

    // To also enable single-sign-out set the url for your auth-server's logout-endpoint here
    this.oauthService.logoutUrl = gitCredentials.apiUrl + 'oauth/logout';

    // WORKAROUND for partial supported Implicit Flow
    this.oauthService.oidc = false;
    this.oauthService.requireHttps = false;
    this.oauthService['config'] = {
      oidc: false,
      responseType: 'token',
      openUri: (loginUrl) => (window.location.href = loginUrl),
    };

    // This method just tries to parse the token(s) within the url when
    // the auth-server redirects the user back to the web-app
    // It doesn't send the user the the login page
    this.oauthService.tryLoginImplicitFlow().then((isLoggedIn) => {
      if (!isLoggedIn) {
        this.oauthService.initImplicitFlow();
      }
    });
  }
}
