<mat-form-field class="name-form-field">
  <input
    matInput
    type="text"
    placeholder="Title"
    [(ngModel)]="uiElement.name"
  />
</mat-form-field>
<mat-tab-group>
  <mat-tab label="General Information">
    <uxd-general-information
      [currentUiElement]="currentUiElement"
    ></uxd-general-information>
  </mat-tab>
  <mat-tab label="Properties">
    <uxd-properties [currentUiElement]="currentUiElement"></uxd-properties>
  </mat-tab>
  <mat-tab label="States">
    <uxd-states [currentUiElement]="currentUiElement"></uxd-states>
  </mat-tab>
  <mat-tab label="Content">
    <uxd-content [currentUiElement]="currentUiElement"></uxd-content>
  </mat-tab>
</mat-tab-group>
