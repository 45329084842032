import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { Session } from 'src/app/models/session';
import { GitService } from 'src/app/services/git.service';
import { UiElementService } from 'src/app/services/ui-element.service';
import { UiElement } from 'src/app/models/ui-element';
import { MatDialog } from '@angular/material/dialog';
import { CredentialsComponent } from '../credentials/credentials.component';
import { NavigationService } from 'src/app/services/navigation.service';
import { UIELEMENT } from '../../default-data/default-uielement';
import * as uuidv4 from 'uuid/v4';

@Component({
  selector: 'uxd-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() currentUiElement = '';
  @Output() currentUiElementChange: EventEmitter<any>;
  constructor(
    private sessionService: SessionService,
    private gitService: GitService,
    private uiElementService: UiElementService,
    private navigationService: NavigationService,
    public dialog: MatDialog
  ) {
    this.currentUiElementChange = new EventEmitter(true);
  }
  session: Session;
  uiElements: UiElement[];
  ngOnInit() {
    this.session = this.sessionService.getSession();
    this.uiElements = this.uiElementService.getUiElements();
    this.getGit();
  }

  getGit() {
    this.gitService.getGit().then((uiElements: UiElement[]) => {
      // console.log(uiElements);
      this.uiElementService.resetUiElements();
      Object.assign(this.uiElements, uiElements);
      this.currentUiElement = this.uiElements[0].guid;
      this.currentUiElementChange.emit(this.currentUiElement);
      // this.uiElements[0].name = uiElements[0].name;
      this.navigationService.updateNavigation();
      this.sessionService.resetChangedElements();
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CredentialsComponent, {
      width: '500px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      localStorage.setItem('gitcredentials', JSON.stringify(this.session.gitCredentials));
      this.getGit();
    });
  }

  addElement() {
    const uiElement = new UiElement();
    Object.assign(uiElement, JSON.parse(JSON.stringify(UIELEMENT)));
    console.log(UIELEMENT);
    uiElement.guid = uuidv4();
    uiElement.layout = 'control';
    uiElement.related = [];
    uiElement.categories = JSON.parse(JSON.stringify(this.uiElementService.getUiElement(this.currentUiElement).categories));

    // uiElement.metaData = [{ name: 'Categories', key: 'categories', list: [] }];
    this.uiElements.push(uiElement);
    this.currentUiElement = uiElement.guid;
    this.currentUiElementChange.emit(this.currentUiElement);
    this.navigationService.updateNavigation();
    console.log(uiElement);
  }

  commitChanges() {
    this.gitService.putChanged();
  }
}
