import { Injectable } from '@angular/core';
import { from, Subject, Observable } from 'rxjs';
import { concatMap, takeUntil, map } from 'rxjs/operators';
import { UIELEMENTS } from '../default-data/default-uielements';
import * as yaml from 'yaml-js';
import { UiElement } from '../models/ui-element';
import { State } from '../models/state';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from './session.service';
import { UiElementService } from './ui-element.service';
import { DatePipe } from '@angular/common';
import { NavigationService } from './navigation.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class GitService {
  public api: any;
  public gitCredentials: any;
  public message = '';

  constructor(
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private sessionService: SessionService,
    private uiElementService: UiElementService,
  ) {}

  init() {
    this.gitCredentials = this.sessionService.getSession().gitCredentials;
  }

  getRequestFilePath(path) {
    // console.log(path);
    return (
      this.gitCredentials.apiUrl +
      'api/v4/projects/' +
      this.gitCredentials.projectId +
      '/repository/files/' +
      encodeURIComponent(path) +
      '?ref=' +
      this.gitCredentials.branch
    );
  }

  getFolderContentPath() {
    const returnPath =
      this.gitCredentials.apiUrl +
      'api/v4/projects/' +
      this.gitCredentials.projectId +
      '/repository/tree?id=' +
      this.gitCredentials.projectId +
      '&path=' +
      encodeURIComponent(this.gitCredentials.path) +
      '&per_page=1000' +
      '&recursive=false&ref=' +
      this.gitCredentials.branch;
    return returnPath;
  }
  getFolderContent(elementGuid, layout) {
    layout = 'control';
    const returnPath =
      this.gitCredentials.apiUrl +
      'api/v4/projects/' +
      this.gitCredentials.projectId +
      '/repository/tree?id=' +
      this.gitCredentials.projectId +
      '&path=' +
      encodeURIComponent(this.gitCredentials.path + '/') +
      elementGuid +
      '.' +
      layout +
      '&per_page=10000' +
      '&recursive=false&ref=' +
      this.gitCredentials.branch;
    return returnPath;
  }

  getBaseFolderContent() {
    const returnPath =
      this.gitCredentials.apiUrl +
      'api/v4/projects/' +
      this.gitCredentials.projectId +
      '/repository/tree?id=' +
      this.gitCredentials.projectId +
      '&page=1' +
      '&path=' +
      encodeURIComponent(this.gitCredentials.path) +
      '&per_page=10000' +
      '&recursive=false&ref=' +
      this.gitCredentials.branch;
    return returnPath;
  }

  getManualImagePath(path) {
    return this.gitCredentials.imagePath + path;
  }

  getStateImagePath(guid, elementGuid, layout) {
    layout = 'control';
    return (
      this.gitCredentials.apiUrl +
      this.gitCredentials.imagePath +
      this.gitCredentials.branch +
      '/' +
      this.gitCredentials.path +
      '/' +
      elementGuid +
      '.' +
      layout +
      '/' +
      guid +
      '.state.png'
    );
  }

  getPutImagePath(filename, elementGuid, layout) {
    layout = 'control';
    return (
      this.gitCredentials.apiUrl +
      'api/v4/projects/' +
      this.gitCredentials.projectId +
      '/repository/files/' +
      encodeURIComponent(this.gitCredentials.path + '/') +
      elementGuid +
      '.' +
      layout +
      encodeURIComponent('/') +
      filename
    );
  }

  getImagePath(guid) {
    return (
      this.gitCredentials.apiUrl +
      this.gitCredentials.imagePath +
      this.gitCredentials.branch +
      '/' +
      this.gitCredentials.path +
      '/' +
      guid +
      '.' +
      // uiElement.layout +
      'control' +
      '/image.png'
    );
  }

  getPutDataPath(elementGuid, layout) {
    layout = 'control';
    // https://gitlab.centigrade.de/api/v4/projects/25/repository/files/source%2F_posts%2Fa112440d-c7db-7f65-cf29-51f6ae08e891.uiElement.md
    // a112440d-c7db-7f65-cf29-51f6ae08e891.uiElement.md

    return (
      this.gitCredentials.apiUrl +
      'api/v4/projects/' +
      this.gitCredentials.projectId +
      '/repository/files/' +
      encodeURIComponent(this.gitCredentials.path + '/') +
      elementGuid +
      '.' +
      layout +
      encodeURIComponent('.md')
    );
  }

  getGit() {
    return new Promise((resolve, reject) => {
      const uiElements: UiElement[] = [];
      this.init();
      this.getGitFile(this.getFolderContentPath() + '&page=1').subscribe((folderContent1: any) => {
        this.getGitFile(this.getFolderContentPath() + '&page=2').subscribe(
          (folderContent2: any) => {
            this.getGitFile(this.getFolderContentPath() + '&page=3').subscribe(
              (folderContent3: any) => {
                this.getGitFile(this.getFolderContentPath() + '&page=4').subscribe(
                  (folderContent4: any) => {
                    this.getGitFile(this.getFolderContentPath() + '&page=5').subscribe(
                      (folderContent5: any) => {
                        this.getGitFile(this.getFolderContentPath() + '&page=6').subscribe(
                          (folderContent6: any) => {
                            this.getGitFile(this.getFolderContentPath() + '&page=7').subscribe(
                              (folderContent7: any) => {
                                this.getGitFile(this.getFolderContentPath() + '&page=8').subscribe(
                                  (folderContent8: any) => {
                                    this.getGitFile(
                                      this.getFolderContentPath() + '&page=9',
                                    ).subscribe((folderContent9: any) => {
                                      this.getGitFile(
                                        this.getFolderContentPath() + '&page=10',
                                      ).subscribe((folderContent10: any) => {
                                        this.getGitFile(
                                          this.getFolderContentPath() + '&page=11',
                                        ).subscribe((folderContent11: any) => {
                                          this.getGitFile(
                                            this.getFolderContentPath() + '&page=12',
                                          ).subscribe(
                                            (folderContent12: any) => {
                                              const folderContent = folderContent1
                                                .concat(folderContent2)
                                                .concat(folderContent3)
                                                .concat(folderContent4)
                                                .concat(folderContent5)
                                                .concat(folderContent6)
                                                .concat(folderContent7)
                                                .concat(folderContent8)
                                                .concat(folderContent9)
                                                .concat(folderContent10)
                                                .concat(folderContent11)
                                                .concat(folderContent12);
                                              const elements = folderContent.filter(
                                                (file: any) =>
                                                  file.name.indexOf('control.md') > -1 ||
                                                  file.name.indexOf('screen.md') > -1 ||
                                                  file.name.indexOf('component.md') > -1 ||
                                                  file.name.indexOf('icon.md') > -1,
                                              );
                                              // console.log(folderContent);
                                              /*           this.getGitFile(this.getRequestFilePath(elements[0].path)).subscribe(
            fileContent => console.log(fileContent)
          ); */
                                              const getFiles = from(elements).pipe(
                                                concatMap((file: any) =>
                                                  this.getGitFile(
                                                    this.getRequestFilePath(file.path),
                                                  ).pipe(
                                                    map((fileData: any) => {
                                                      // console.log(fileData);
                                                      // console.log(file.path);
                                                      const elementData = this.yml2json(
                                                        fileData.content,
                                                      );
                                                      // console.log(JSON.stringify(elementData));
                                                      uiElements.push(elementData);
                                                    }),
                                                  ),
                                                ),
                                              );

                                              const unsubscribeGetFilesData = new Subject();

                                              getFiles
                                                .pipe(takeUntil(unsubscribeGetFilesData))
                                                .subscribe(
                                                  (val) => val,
                                                  (err) => {
                                                    console.error('Observer got an error: ' + err);
                                                    this.message = err;
                                                    this.snackBar.open(this.message, null, {
                                                      verticalPosition: 'top',
                                                    });
                                                  },
                                                  () => {
                                                    console.log(
                                                      'Observer got a complete notification',
                                                    );
                                                    unsubscribeGetFilesData.next();
                                                    unsubscribeGetFilesData.complete();
                                                    console.log('Unsubscribed Files Data');
                                                    this.snackBar.dismiss();
                                                    uiElements.sort((a, b) =>
                                                      a.name.localeCompare(b.name),
                                                    );
                                                    uiElements.sort((a, b) =>
                                                      String(a.listIndex).localeCompare(
                                                        String(b.listIndex),
                                                      ),
                                                    );
                                                    resolve(uiElements);
                                                    // resolve(this.sortByKey(uiElements, 'categories'));
                                                    // console.log(JSON.stringify(uiElements), null, '\t');
                                                  },
                                                );
                                            },
                                            (error) => {
                                              this.message = JSON.parse(error.error).message;
                                              this.snackBar.open(this.message, null, {
                                                verticalPosition: 'top',
                                              });
                                            },
                                          );
                                        });
                                      });
                                    });
                                  },
                                );
                              },
                            );
                          },
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      });
    });
  }

  putChanged() {
    return new Promise((resolve, reject) => {
      this.message = 'committing files';
      this.snackBar.open(this.message, null, { verticalPosition: 'top' });
      const session = this.sessionService.getSession();
      // this.putGit(session.changedElements[0]);

      const putFiles = from(session.changedElements).pipe(
        concatMap((changedElement: any) => this.putGit(changedElement)),
      );

      const unsubscribeGetFilesData = new Subject();

      putFiles.pipe(takeUntil(unsubscribeGetFilesData)).subscribe(
        (val) => val,
        (err) => console.error('Observer got an error: ' + err),
        () => {
          console.log('Observer got a complete notification');
          unsubscribeGetFilesData.next();
          unsubscribeGetFilesData.complete();
          console.log('Unsubscribed Files Data');
          this.snackBar.dismiss();
          session.changedElements = [];
          // console.log(JSON.stringify(uiElements), null, '\t');
          resolve('done');
        },
      );
    });
  }

  putGit(guid: string) {
    return new Promise((resolve, reject) => {
      const uiElement = this.uiElementService.getUiElement(guid);
      this.message = 'Committing file ' + uiElement.name;
      this.snackBar.open(this.message, null, { verticalPosition: 'top' });
      const dataPath = this.getPutDataPath(uiElement.guid, 'control');
      const elementData = this.jsonToYml(guid);
      console.log(elementData);
      console.log(dataPath);
      this.getGitFile(this.getBaseFolderContent() + '&page=1').subscribe((folderContent1: any) => {
        this.getGitFile(this.getBaseFolderContent() + '&page=2').subscribe(
          (folderContent2: any) => {
            this.getGitFile(this.getBaseFolderContent() + '&page=3').subscribe(
              (folderContent3: any) => {
                this.getGitFile(this.getBaseFolderContent() + '&page=4').subscribe(
                  (folderContent4: any) => {
                    this.getGitFile(this.getBaseFolderContent() + '&page=5').subscribe(
                      (folderContent5: any) => {
                        this.getGitFile(this.getBaseFolderContent() + '&page=6').subscribe(
                          (folderContent6: any) => {
                            this.getGitFile(this.getBaseFolderContent() + '&page=7').subscribe(
                              (folderContent7: any) => {
                                this.getGitFile(this.getBaseFolderContent() + '&page=8').subscribe(
                                  (folderContent8: any) => {
                                    this.getGitFile(
                                      this.getBaseFolderContent() + '&page=9',
                                    ).subscribe((folderContent9: any) => {
                                      this.getGitFile(
                                        this.getBaseFolderContent() + '&page=10',
                                      ).subscribe((folderContent10: any) => {
                                        this.getGitFile(
                                          this.getBaseFolderContent() + '&page=11',
                                        ).subscribe((folderContent11: any) => {
                                          this.getGitFile(
                                            this.getBaseFolderContent() + '&page=12',
                                          ).subscribe((folderContent12: any) => {
                                            const folderContent = folderContent1
                                              .concat(folderContent2)
                                              .concat(folderContent3)
                                              .concat(folderContent4)
                                              .concat(folderContent5)
                                              .concat(folderContent6)
                                              .concat(folderContent7)
                                              .concat(folderContent8)
                                              .concat(folderContent9)
                                              .concat(folderContent10)
                                              .concat(folderContent11)
                                              .concat(folderContent12);
                                            const elementExists = folderContent.find(
                                              (file: any) =>
                                                file.name === uiElement.guid + '.control.md',
                                            );
                                            let method = 'post';
                                            if (elementExists) {
                                              method = 'put';
                                            }

                                            this.saveGitFile(
                                              dataPath,
                                              btoa(unescape(encodeURIComponent(elementData))),
                                              method,
                                              uiElement.name,
                                            ).subscribe((result: any) => resolve(result));
                                          });

                                          // https://gitlab.centigrade.de/api/v4/projects/25/repository/files/source%2F_posts%2Fa112440d-c7db-7f65-cf29-51f6ae08e891.uiElement.md
                                        });
                                      });
                                    });
                                  },
                                );
                              },
                            );
                          },
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      });
    });
  }

  yml2json(file) {
    const parts = atob(file).split('---\n');
    // console.log(decodeURIComponent(escape(parts[1])));
    // console.log(parts[1]);
    const data = yaml.load_all(decodeURIComponent(escape(parts[1])));
    const yamlData = data[0];
    let contentData = '';
    for (let n = 2; n < parts.length; n++) {
      contentData += decodeURIComponent(escape(parts[n]));
    }
    // console.log(parts[2]);
    const uiElement = new UiElement();
    if (contentData) {
      uiElement.content = contentData;
    } else {
      uiElement.content = '';
    }

    if (yamlData.hideimage) {
      // console.log(yamlData.hideimage);
      uiElement.hideImage = yamlData.hideimage;
    }

    if (yamlData.listIndex) {
      // console.log(yamlData.listIndex);
      uiElement.listIndex = yamlData.listIndex;
    }

    if (yamlData.tags) {
      uiElement.tags = yamlData.tags;
    }
    if (yamlData.related) {
      // uiElement.related = [];
      uiElement.related = yamlData.related;
    } else {
      uiElement.related = [];
    }

    if (yamlData.categories) {
      uiElement.categories = yamlData.categories;
      /* if (typeof yamlData.categories[0] === 'string') {
        uiElement.metaData.push({
          name: 'Categories',
          key: 'categories',
          list: yamlData.categories
        });
        uiElement.categories = yamlData.categories;
      } else {
        uiElement.metaData.push({
          name: 'Categories',
          key: 'categories',
          list: [yamlData.categories[0]]
        });
        uiElement.categories = yamlData.categories[0];
      } */
    } else {
      /* uiElement.metaData.push({
        name: 'Categories',
        key: 'categories',
        list: []
      }); */
      uiElement.categories = [];
    }

    // console.log(yamlData);
    ['properties'].forEach((folderName) => {
      for (let n = 0; n < UIELEMENTS[0][folderName].length; n++) {
        if (yamlData[UIELEMENTS[0][folderName][n].key]) {
          if (yamlData[UIELEMENTS[0][folderName][n].key].length) {
            uiElement[folderName].push({
              name: UIELEMENTS[0][folderName][n].name,
              key: UIELEMENTS[0][folderName][n].key,
              list: yamlData[UIELEMENTS[0][folderName][n].key],
            });
          } else {
            uiElement[folderName].push(JSON.parse(JSON.stringify(UIELEMENTS[0][folderName][n])));
            uiElement[folderName][uiElement[folderName].length - 1].list = [];

            for (let m = 0; m < UIELEMENTS[0][folderName][n].list.length; m++) {
              if (
                yamlData[UIELEMENTS[0][folderName][n].key][UIELEMENTS[0][folderName][n].list[m].key]
              ) {
                if (
                  yamlData[UIELEMENTS[0][folderName][n].key][
                    UIELEMENTS[0][folderName][n].list[m].key
                  ]
                ) {
                  if (!uiElement[folderName][n].list) {
                    uiElement[folderName][n].list = [];
                  }
                  uiElement[folderName][n].list.push({
                    name: UIELEMENTS[0][folderName][n].list[m].name,
                    key: UIELEMENTS[0][folderName][n].list[m].key,
                    list:
                      yamlData[UIELEMENTS[0][folderName][n].key][
                        UIELEMENTS[0][folderName][n].list[m].key
                      ],
                  });
                }
              } else {
                uiElement[folderName][n].list.push({
                  name: UIELEMENTS[0][folderName][n].list[m].name,
                  key: UIELEMENTS[0][folderName][n].list[m].key,
                  list: [],
                });
                /*  uiElement[folderName][n].list.push(
                  JSON.parse(JSON.stringify(UIELEMENTS[0][folderName][n].list[m]))
                ); */
              }
            }
          }
        } else {
          uiElement[folderName].push(JSON.parse(JSON.stringify(UIELEMENTS[0][folderName][n])));
        }
      }
    });

    uiElement.name = yamlData.title;
    this.message = 'Getting Git Data: ' + uiElement.name;
    this.snackBar.open(this.message, null, { verticalPosition: 'top' });
    // console.log(uiElement.name);
    uiElement.guid = yamlData.guid;
    uiElement.layout = yamlData.layout || yamlData.type;
    if (yamlData.variantOf) {
      uiElement.variantOf = yamlData.variantOf;
    } else {
      uiElement.variantOf = '';
    }
    uiElement.imagePath = this.getImagePath(uiElement.guid);

    if (yamlData.manualImage) {
      uiElement.manualImagePath = yamlData.manualImage;
    } else {
      uiElement.manualImagePath = '';
    }

    if (yamlData.states) {
      if (!uiElement.states) {
        uiElement.states = [];
      }
      for (let m = 0; m < yamlData.states.length; m++) {
        const state = new State();
        state.guid = yamlData.states[m].guid;
        state.name = yamlData.states[m].name;
        state.src =
          // 'url(' +
          this.getStateImagePath(state.guid, uiElement.guid, 'control');
        // +')';
        state.description = [];
        if (Array.isArray(yamlData.states[m].description)) {
          state.description.push(...yamlData.states[m].description);
        } else {
          state.description.push(yamlData.states[m].description);
        }

        if (yamlData.states[m].manualImage) {
          state.manualImagePath = yamlData.states[m].manualImage;
        } else {
          state.manualImagePath = '';
        }

        // console.log(state);
        state.properties = [
          { name: 'Appearance', key: 'appearance', list: [] },
          { name: 'Measurements', key: 'measurements', list: [] },
          { name: 'Interaction', key: 'interaction', list: [] },
        ];
        if (yamlData.states[m].appearance) {
          for (let p = 0; p < yamlData.states[m].appearance.length; p++) {
            state.properties[0].list.push(yamlData.states[m].appearance[p]);
          }
        }
        if (yamlData.states[m].measurements) {
          for (let p = 0; p < yamlData.states[m].measurements.length; p++) {
            state.properties[0].list.push(yamlData.states[m].measurements[p]);
          }
        }
        if (yamlData.states[m].interaction) {
          for (let p = 0; p < yamlData.states[m].interaction.length; p++) {
            state.properties[1].list.push(yamlData.states[m].interaction[p]);
          }
        }
        uiElement.states.push(state);
      }
    }
    if (yamlData.imagemap) {
      // console.log(yamlData.imagemap);
      uiElement.imageMap = yamlData.imagemap;
    }

    if (yamlData.imageDimensions) {
      console.log(yamlData.imagemap);
      uiElement.imageDimensions = yamlData.imageDimensions;
    } else {
      console.log('dimensions missing');
      uiElement.imageDimensions = { width: 1280, height: 720, scale: 0.3 };
    }
    // console.log(yamlData.imageDimensions);
    return uiElement;
  }

  public jsonToYml(guid: string) {
    const uiElement = this.uiElementService.getUiElement(guid);
    const d = new Date();
    const n = d.getTime();
    const date = new DatePipe('en-US').transform(n, 'yyyy-MM-dd HH:mm:ss');
    let uiElementMdData = '';
    uiElementMdData += `---
type: ${this.prepareMd(uiElement.layout)}
title: "${this.prepareMd(uiElement.name)}"
date: ${date}`;

    uiElementMdData += `
categories:
 - [`;
    if (uiElement.categories && uiElement.categories[0]) {
      for (let p = 0; p < uiElement.categories[0].length; p++) {
        uiElementMdData += '"' + this.prepareMd(uiElement.categories[0][p]);
        uiElementMdData += '"';
        if (p < uiElement.categories[0].length - 1) {
          uiElementMdData += ',';
        }
      }
    }
    uiElementMdData += `]
`;
    /*  for (let p = 0; p < uiElement.metaData.length; p++) {
      uiElementMdData += `
${uiElement.metaData[p].key}:
`;
      for (let m = 0; m < uiElement.metaData[p].list.length; m++) {
        uiElementMdData +=
          ' - ' +
          JSON.stringify(
            this.prepareMd(uiElement.metaData[p].list[m]).split(',')
          ) +
          '\n';
      }
    } */
    uiElementMdData += `
tags:
`;
    if (uiElement.tags) {
      for (let m = 0; m < uiElement.tags.length; m++) {
        uiElementMdData += ' - "' + this.prepareMd(uiElement.tags[m]) + '"\n';
      }
    }

    uiElementMdData += `
guid: "${this.prepareMd(uiElement.guid)}"

variantOf: "${this.prepareMd(uiElement.variantOf)}"

hideimage: ${uiElement.hideImage === true}

listIndex: ${uiElement.listIndex ? uiElement.listIndex : 0}


image: 'image.png'

manualImage: "${uiElement.manualImagePath || ''}"`;

    if (uiElement.imageDimensions) {
      uiElementMdData += `
imageDimensions:
  width: ${uiElement.imageDimensions.width}
  height: ${uiElement.imageDimensions.height}
  scale: ${uiElement.imageDimensions.scale}

`;
    }

    for (let p = 0; p < uiElement.properties.length; p++) {
      uiElementMdData += `
${uiElement.properties[p].key}:
`;

      for (let m = 0; m < uiElement.properties[p].list.length; m++) {
        if (!this.prepareMd(uiElement.properties[p].list[m].key)) {
          uiElementMdData += ' - "' + this.prepareMd(uiElement.properties[p].list[m]) + '"\n';
        } else {
          uiElementMdData += '   ' + this.prepareMd(uiElement.properties[p].list[m].key) + ':\n';
          for (let q = 0; q < uiElement.properties[p].list[m].list.length; q++) {
            uiElementMdData +=
              '    - "' + this.prepareMd(uiElement.properties[p].list[m].list[q]) + '"\n';
          }
        }
      }
    }

    if (uiElement.states) {
      uiElementMdData += `

states:
`;
      for (let m = 0; m < uiElement.states.length; m++) {
        uiElementMdData += `
  - guid: "${this.prepareMd(uiElement.states[m].guid)}"
    name: "${this.prepareMd(uiElement.states[m].name)}"
    manualImage: "${uiElement.manualImagePath || ''}"
    description:
`;
        for (let p = 0; p < uiElement.states[m].description.length; p++) {
          uiElementMdData +=
            '     - "' + this.prepareMd(uiElement.states[m].description[p]) + '"\n';
        }

        for (let r = 0; r < uiElement.states[m].properties.length; r++) {
          uiElementMdData += `    ${this.prepareMd(uiElement.states[m].properties[r].key)}:
`;
          for (let p = 0; p < uiElement.states[m].properties[r].list.length; p++) {
            uiElementMdData +=
              '     - "' + this.prepareMd(uiElement.states[m].properties[r].list[p]) + '"\n';
          }
        }
      }
    }
    uiElementMdData += `

related:
`;
    for (let m = 0; m < uiElement.related.length; m++) {
      uiElementMdData += ' - "' + uiElement.related[m] + '"\n';
    }

    if (uiElement.imageMap) {
      uiElementMdData += `

imagemap:
`;
      const imageMapKeys = Object.keys(uiElement.imageMap);
      for (let m = 0; m < imageMapKeys.length; m++) {
        uiElementMdData += '   ' + imageMapKeys[m] + ':\n';
        uiElementMdData += '     x: ' + uiElement.imageMap[imageMapKeys[m]].x + '\n';
        uiElementMdData += '     y: ' + uiElement.imageMap[imageMapKeys[m]].y + '\n';
        uiElementMdData += '     width: ' + uiElement.imageMap[imageMapKeys[m]].width + '\n';
        uiElementMdData += '     height: ' + uiElement.imageMap[imageMapKeys[m]].height + '\n';
      }
    }
    uiElementMdData += `

---`;

    if (uiElement.content) {
      uiElementMdData += '\n' + uiElement.content;
    }

    console.log(uiElementMdData);
    return uiElementMdData;
  }

  uploadImage(data: any, guid, elementGuid, layout, message) {
    let filename = guid + '.state.png';
    if (guid === 'image') {
      filename = 'image.png';
    }
    return new Promise((resolve, reject) => {
      this.getGitFile(this.getFolderContent(elementGuid, layout)).subscribe(
        (folderContent: any) => {
          const elementExists = folderContent.find((file: any) => file.name === filename);
          let method = 'post';
          if (elementExists) {
            method = 'put';
          }

          console.log(method);

          console.log(folderContent);
          this.saveGitFile(
            this.getPutImagePath(filename, elementGuid, layout),
            data,
            method,
            message,
          ).subscribe((result: any) => resolve(result));
        },
      );
    });
  }

  getGitFile(url): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.set('accept', 'application/json');
    return this.http.get(url, { headers: headers, responseType: 'text' }).pipe(
      map((rawData) => {
        return JSON.parse(rawData);
      }),
    );
  }
  saveGitFile(url, data, method, message): Observable<string> {
    const commitData = {
      branch: this.gitCredentials.branch,
      commit_message: message,
      content: data,
      encoding: 'base64',
    };
    let headers = new HttpHeaders();
    headers = headers.set('accept', 'application/json');
    headers = headers.append('content-type', 'application/json;');
    if (method === 'post') {
      return this.http.post(url, commitData, {
        headers: headers,
        responseType: 'text',
      });
    }
    if (method === 'put') {
      console.log(url);
      return this.http.put(url, commitData, {
        headers: headers,
        responseType: 'text',
      });
    }
  }

  getImageData(currentUiElement: string) {
    return new Promise((resolve, reject) => {
      const path = this.getRequestFilePath(
        this.gitCredentials.path + '/' + currentUiElement + '.control/image.png',
      );

      this.getGitFile(path).subscribe((imageFile: any) => {
        // console.log('data:image/png;base64,' + imageFile.content);
        resolve('data:image/png;base64,' + imageFile.content);
      });
    });
  }

  getStateImageData(currentUiElement: string, state: string) {
    return new Promise((resolve, reject) => {
      const path = this.getRequestFilePath(
        this.gitCredentials.path + '/' + currentUiElement + '.control/' + state + '.state.png',
      );

      this.getGitFile(path).subscribe((imageFile: any) => {
        // console.log('data:image/png;base64,' + imageFile.content);
        resolve('data:image/png;base64,' + imageFile.content);
      });
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  prepareMd(data: string) {
    console.log(data);
    if (data) {
      return data.toString().replace(/\n/g, '').replace(/"/g, '\\"');
    } else {
      return '';
    }
  }

  sortByKey(array, key) {
    return array.sort(function (a, b) {
      const x = a[key.replace(/ /g, '_')];
      const y = b[key.replace(/ /g, '_')];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }
}
