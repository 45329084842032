<div class="markdown-container">
  <mat-card class="half">
    <mat-card-title>Markdown</mat-card-title>
    <mat-card-content>
      <mat-form-field class="full-width">
        <mat-label>Markdown Content</mat-label>
        <textarea
          matInput
          [(ngModel)]="uiElement.content"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="2"
          cdkAutosizeMaxRows="50"
        ></textarea>
      </mat-form-field>
    </mat-card-content>
  </mat-card>
  <mat-card class="half">
    <mat-card-title>Result</mat-card-title>
    <mat-card-content>
      <markdown *ngIf="uiElement.content" [data]="uiElement.content" ngPreserveWhitespaces>
        # Markdown
      </markdown>
    </mat-card-content>
  </mat-card>
</div>
