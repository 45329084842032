import { UiElementProperty } from './ui-element-property';
import {ImageDimensions} from './image-dimensions';
import { State } from './state';

export class UiElement {
  guid = '';
  name = '';
  imagePath = '';
  manualImagePath = '';
  imageDimensions?: ImageDimensions;
  properties: UiElementProperty[] = [];
  layout = '';
  states: State[] = [];
  tags: string[] = [];
  related: string[] = [];
  categories: string[][] = [];
  imageMap?: any;
  hideImage?: boolean;
  listIndex?: number;
  variantOf?: string;
  content = '';
}
