import { Pipe, PipeTransform } from '@angular/core';
import { UiElement } from '../models/ui-element';

@Pipe({
  name: 'isType'
})
export class IsTypePipe implements PipeTransform {
  transform(value: UiElement[], args?: any): any {
    if (value) {
      if (args !== 'specification') {
        return value.filter(element => element.layout !== 'specific');
      } else {
        return value.filter(element => element.layout === 'specific');
      }
    } else {
      return value;
    }
  }
}
