import { Component, Input, OnChanges } from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop';
import { UiElement } from 'src/app/models/ui-element';
import { UiElementService } from 'src/app/services/ui-element.service';
import { GitService } from 'src/app/services/git.service';
import { State } from 'src/app/models/state';
import { FormControl } from '@angular/forms';
import * as uuidv4 from 'uuid/v4';
import { ImageDimensions } from 'src/app/models/image-dimensions';

@Component({
  selector: 'uxd-states',
  templateUrl: './states.component.html',
  styleUrls: ['./states.component.scss']
})
export class StatesComponent implements OnChanges {
  @Input() currentUiElement = '';
  uiElement: UiElement;
  uiElements: UiElement[];
  viewScale = 2;

  relatedElements = new FormControl();

  constructor(
    private uiElementService: UiElementService,
    private gitService: GitService
  ) {}

  ngOnChanges() {
    this.uiElement = this.uiElementService.getUiElement(this.currentUiElement);
    for (let n = 0; n < this.uiElement.states.length; n++) {
      this.getStateImage(n);
    }
    this.uiElements = this.uiElementService.getUiElements();
  }

  getStateImage(n) {
    if (this.uiElement.states[n].manualImagePath) {
      this.uiElement.states[n].src = this.gitService.getManualImagePath(this.uiElement.states[n].manualImagePath);
    } else {
    this.gitService
      .getStateImageData(this.currentUiElement, this.uiElement.states[n].guid)
      .then((imagepath: string) => {
        // console.log(imagepath);
        this.uiElement.states[n].src = imagepath;
      });
    }
  }

  getImageOnReturn(e: any, n) {
    if (e.which === 13 || e.keyCode === 13) {
      e.preventDefault();
      this.getStateImage(n);
    }
  }

  addState() {
    const state = new State();
    state.guid = uuidv4();
    state.properties = [
      { name: 'General Appearance', key: 'appearance', list: [] },
      { name: 'Measurements', key: 'measurements', list: [] },
      { name: 'Interaction', key: 'interaction', list: [] }
    ];
    state.description = [];
    if (!this.uiElement.states) {
      this.uiElement.states = [];
    }
    this.uiElement.states.push(state);
    console.log(this.uiElement);
  }

  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }
  onDrop(event, guid, i) {
    event.preventDefault();
    this.readDroppedFile(event.dataTransfer.files, guid, i);
  }

  readDroppedFile(inputValue: any, guid, i): void {
    const file: File = inputValue[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = e => {
      const loadedData: any = myReader.result;
      this.uploadImageToGit(loadedData, guid, i);
    };

    myReader.readAsDataURL(file);
  }

  addItem(target: string[]) {
    target.push('');
    console.log(this.uiElement);
  }

  removeItem(arrayObject, index) {
    arrayObject.splice(index, 1);
  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  processPasted(event: any, i) {
    event.preventDefault();
    console.log('paste');
    const items = event.clipboardData.items;

    let blob: any = null;
    for (let k = 0; k < items.length; k++) {
      if (items[k].type.indexOf('image') === 0) {
        blob = items[k].getAsFile();
      }
    }

    if (blob !== null) {
      const reader = new FileReader();
      reader.addEventListener('loadend', (loadEvent: any) => {
        console.log(loadEvent.target.result);
        const img = new Image();
        img.onload = () => {
          // alert(img.width + 'x' + img.height);
          /* if (!this.uiElement.imageDimensions) {
            this.uiElement.imageDimensions = new ImageDimensions();
          }
          this.uiElement.imageDimensions.width = img.width;
          this.uiElement.imageDimensions.height = img.height;
          this.uiElement.imageDimensions.scale = 512 / img.width; */
        };
        img.src = loadEvent.target.result;
        this.uploadImageToGit(
          loadEvent.target.result,
          this.uiElement.states[i].guid,
          i
        );
        // this.uploadImageToGit(loadEvent.target.result, 'image', i);
        // this.artifactmodel.image = loadEvent.target.result;

        // this.setImage(loadEvent.target.result);
        // this.notifyChange();
      });
      reader.onload = () => {};
      reader.readAsDataURL(blob);
    }
  }

  uploadImageToGit(loadedData, guid, i) {
    const base64 = loadedData.split(',')[1];
    this.gitService
      .uploadImage(
        base64,
        guid,
        this.uiElement.guid,
        this.uiElement.layout,
        'Added Image for ' + this.uiElement.states[i].name
      )
      .then(_ => {
        this.uiElement.states[i].src = loadedData;
        // 'url(' +
        /*  this.gitService.getStateImagePath(
            guid,
            this.uiElement.guid,
            this.uiElement.layout
          ) + '?random=0'; */

        // + ')';
      });
  }
  notLoggedIn() {
    console.log('NotLoggedIn');
  }
}
