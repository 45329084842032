import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  KeyValueDiffer,
  KeyValueDiffers,
  DoCheck,
  KeyValueChanges
} from '@angular/core';
import { UiElement } from 'src/app/models/ui-element';
import { UiElementService } from 'src/app/services/ui-element.service';
import { FormControl } from '@angular/forms';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'uxd-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnChanges, DoCheck {
  @Input() currentUiElement = '';
  uiElement: UiElement;
  uiElements: UiElement[];
  relatedElements = new FormControl();
  differ: KeyValueDiffer<string, any>;
  skipDiffer = true;

  constructor(
    private sessionService: SessionService,
    private uiElementService: UiElementService,
    private differs: KeyValueDiffers
  ) {}

  ngDoCheck() {
    const changes = this.differ.diff({
      change: JSON.stringify(this.uiElement)
    });
    if (changes) {
      this.elementChanged(changes);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.skipDiffer = true;
    this.uiElement = this.uiElementService.getUiElement(this.currentUiElement);

    this.uiElements = this.uiElementService.getUiElements();
    this.differ = this.differs.find({}).create();
  }

  elementChanged(changes: KeyValueChanges<string, any>) {
    console.log(changes);
    if (!this.skipDiffer) {
      this.markChanged();
    } else {
      this.skipDiffer = false;
    }
  }
  markChanged() {
    this.sessionService.addChangedElement(this.uiElement.guid);
  }
}
