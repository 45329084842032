<button mat-icon-button [mat-menu-trigger-for]="themeMenu" matTooltip="Select a theme!"
        tabindex="-1">
  <mat-icon>format_color_fill</mat-icon>
</button>

<!-- TODO: replace use of `mat-menu` here with a custom overlay -->
<mat-menu class="docs-theme-picker-menu" #themeMenu="matMenu" x-position="before">
  <mat-grid-list cols="2">
    <mat-grid-tile *ngFor="let theme of themes">
      <div mat-menu-item (click)="installTheme(theme.name)">
        <div class="docs-theme-picker-swatch">
          <mat-icon class="docs-theme-chosen-icon" *ngIf="currentTheme === theme">check_circle</mat-icon>
          <div class="docs-theme-picker-primary" [style.background]="theme.primary"></div>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</mat-menu>
