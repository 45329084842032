<div
  *ngIf="uiElement"
  #dragBox
  class="example-box isresizable"
  [ngStyle]="
    !dragging
      ? {
          transform:
            'translate3d(' +
            this.containerUiElement.imageMap[this.currentUiElement].x *
              (this.containerUiElement.imageDimensions.scale * this.viewScale) +
            'px, ' +
            this.containerUiElement.imageMap[this.currentUiElement].y *
              (this.containerUiElement.imageDimensions.scale * this.viewScale) +
            'px, 0px)',
          width:
            this.containerUiElement.imageMap[this.currentUiElement].width *
              (this.containerUiElement.imageDimensions.scale * this.viewScale) +
            'px',
          height:
            this.containerUiElement.imageMap[this.currentUiElement].height *
              (this.containerUiElement.imageDimensions.scale * this.viewScale) +
            'px'
        }
      : {
          width:
            this.containerUiElement.imageMap[this.currentUiElement].width *
              (this.containerUiElement.imageDimensions.scale * this.viewScale) +
            'px',
          height:
            this.containerUiElement.imageMap[this.currentUiElement].height *
              (this.containerUiElement.imageDimensions.scale * this.viewScale) +
            'px'
        }
  "
  cdkDrag
  (cdkDragMoved)="getCoordinates($event)"
  (cdkDragStarted)="setCoordinates($event); dragging = true"
  (cdkDragDropped)="dragging = false"
  (mousemove)="
    $event.buttons === 1
      ? resized(dragBox.clientWidth, dragBox.clientHeight)
      : null
  "
>
  <div
    cdkDragHandle
    style="display: block; width:100%; height:100%; text-align: left;"
  >
    <div
      style="background-color: rgba(180,0,180,0.8); display: inline-block; color: white;"
    >
      {{ uiElement.name }} <br />{{
        this.containerUiElement.imageMap[this.currentUiElement].x
      }}
      <br />{{ this.containerUiElement.imageMap[this.currentUiElement].y }}
      <br />{{ this.containerUiElement.imageMap[this.currentUiElement].width }}
      <br />{{ this.containerUiElement.imageMap[this.currentUiElement].height }}
    </div>
  </div>
</div>
