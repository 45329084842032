import { Injectable } from '@angular/core';
import { SESSION } from '../default-data/default-session';
@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor() {}
  getSession() {
    const currentSession = SESSION;
    const defaultGitCredentials = currentSession.gitCredentials;

    const gitCredentials = localStorage.getItem('gitcredentials');

    if (gitCredentials) {
      const parsedGitCredentials = JSON.parse(gitCredentials);
      currentSession.gitCredentials = { ...defaultGitCredentials, ...parsedGitCredentials };
    }

    return currentSession;
  }

  addChangedElement(guid: string) {
    if (SESSION.changedElements.indexOf(guid) === -1) {
      SESSION.changedElements.push(guid);
    }
  }

  resetChangedElements() {
    SESSION.changedElements = [];
  }
}
