import {
  Component,
  Input,
  OnChanges,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { UiElement } from 'src/app/models/ui-element';
import { UiElementService } from 'src/app/services/ui-element.service';
import { GitService } from 'src/app/services/git.service';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ImageDimensions } from 'src/app/models/image-dimensions';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'uxd-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss'],
})
export class GeneralInformationComponent implements OnChanges {
  @Input() currentUiElement = '';
  uiElement: UiElement;
  elementImage: string;
  viewScale = 2;
  uiElements: UiElement[];
  tagCtrl = new FormControl();
  filteredTags: Observable<string[]>;
  allTags: string[] = [
    'Actor',
    'Alert Panel',
    'Control',
    'Current Alert Button',
    'Dashboard',
    'Event Marker',
    'Event Marker Details',
    'Instrument',
    'Main Process Bar',
    'Numerical Input',
    'Operator',
    'Performance Curve',
    'Period Bars',
    'Persona',
    'Production Control',
    'Recipe',
    'Recipe List',
    'Search Input',
    'Timeline',
    'UI Element',
    'User',
    'User Flow',
    'User Journey',
  ];
  relatedElements = new FormControl();
  variantOf = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('tagInput', { static: true }) tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

  constructor(
    private uiElementService: UiElementService,
    private gitService: GitService,
    private navigationService: NavigationService,
  ) {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => (tag ? this._filter(tag) : this.allTags.slice())),
    );
  }

  ngOnChanges() {
    this.uiElement = this.uiElementService.getUiElement(this.currentUiElement);
    if (this.currentUiElement !== 'default') {
      this.uiElements = this.uiElementService.getUiElements();
      this.getImage();
    }
  }

  getImage() {
    if (this.uiElement.manualImagePath) {
      this.elementImage = this.gitService.getManualImagePath(this.uiElement.manualImagePath);
    } else {
      this.gitService.getImageData(this.currentUiElement).then((imagepath: string) => {
        // console.log(imagepath);
        this.elementImage = imagepath;
      }),
        (this.elementImage = '');
    }
  }

  getImageOnReturn(e: any) {
    if (e.which === 13 || e.keyCode === 13) {
      e.preventDefault();
      this.getImage();
    }
  }

  addItem(target: string[]) {
    target.push('');
    // console.log(this.uiElement);
  }

  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }
  onDrop(event, guid) {
    event.preventDefault();
    this.readDroppedFile(event.dataTransfer.files, guid);
  }

  readDroppedFile(inputValue: any, guid): void {
    const file: File = inputValue[0];

    const myReader: FileReader = new FileReader();

    myReader.onloadend = e => {
      const loadedData: any = myReader.result;
      this.uploadImageToGit(loadedData, guid);
      // console.log(loadedData);
      const img = new Image();

      img.src = loadedData;

      img.onload = () => {
        this.uiElement.imageDimensions.width = img.width;
        this.uiElement.imageDimensions.height = img.height;
        this.uiElement.imageDimensions.scale = 512 / img.width;
        console.log(this.uiElement.imageDimensions);
      };
    };

    myReader.readAsDataURL(file);
  }

  uploadImageToGit(loadedData, guid) {
    const base64 = loadedData.split(',')[1];
    this.gitService
      .uploadImage(
        base64,
        guid,
        this.uiElement.guid,
        this.uiElement.layout,
        'Added Image for ' + this.uiElement.name,
      )
      .then(_ => {
        this.elementImage = loadedData;
        /*  this.gitService.getImagePath(this.uiElement.guid) +
          '?random' +
          Math.floor(Math.random() * 1000 + 1); */
      });
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      if ((value || '').trim()) {
        this.uiElement.tags.push(value.trim());
      }

      if (input) {
        input.value = '';
      }

      this.tagCtrl.setValue(null);
    }
  }

  remove(tag: string): void {
    const index = this.uiElement.tags.indexOf(tag);

    if (index >= 0) {
      this.uiElement.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.uiElement.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }
  removeItem(arrayObject, index) {
    arrayObject.splice(index, 1);
  }

  processPasted(event: any) {
    event.preventDefault();
    const items = event.clipboardData.items;

    let blob: any = null;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') === 0) {
        blob = items[i].getAsFile();
      }
    }

    if (blob !== null) {
      const reader = new FileReader();
      reader.addEventListener('loadend', (loadEvent: any) => {
        console.log(loadEvent.target.result);
        const img = new Image();
        img.onload = () => {
          // alert(img.width + 'x' + img.height);
          if (!this.uiElement.imageDimensions) {
            this.uiElement.imageDimensions = new ImageDimensions();
          }
          this.uiElement.imageDimensions.width = img.width;
          this.uiElement.imageDimensions.height = img.height;
          this.uiElement.imageDimensions.scale = 512 / img.width;
          console.log(this.uiElement.imageDimensions);
        };
        img.src = loadEvent.target.result;
        this.uploadImageToGit(loadEvent.target.result, 'image');
        // this.artifactmodel.image = loadEvent.target.result;

        // this.setImage(loadEvent.target.result);
        // this.notifyChange();
      });
      reader.onload = () => {};
      reader.readAsDataURL(blob);
    }
  }

  enablePaste() {
    return false; // enable the Paste menu item
  }

  notLoggedIn() {
    console.log('NotLoggedIn');
  }
  rebuildNavigation() {
    this.navigationService.updateNavigation();
  }
}
